import i18n from '../../../i18n.js'

export default {
    state: () => ({
        countries: [],
        personalIdTypes: [],
        genders: [],
        currencies: [],
        moneyOrigins: [],
        riskActivities: [],
        realOwnerTypes: [],
        statutoryTypes: [],
        realOwnerPurposeTypes: [],
        otherItems: []
    }),
    getters: {
        countries (state) {
            const collator = new Intl.Collator(state.lang, { numeric: true, sensitivity: 'base' })
            const countries = state.countries.map(item => ({ ...item, text: i18n.t(`countries.${item.value}`) }))
                .sort((a, b) => {
                    return collator.compare(a.text, b.text)
                })
            const firsts = [null, null, null]
            for (const country of countries) {
                switch (country.value) {
                    case 'CZ':
                        firsts[0] = country
                        break
                    case 'SK':
                        firsts[1] = country
                        break
                    case 'HR':
                        firsts[2] = country
                        break
                }
                if (firsts.filter(item => item).length === 3) {
                    break
                }
            }
            return [...firsts, ...countries.filter(item => !['CZ', 'SK', 'HR'].includes(item.value))]
        },
        currencies (state) {
            const collator = new Intl.Collator(state.lang, { numeric: true, sensitivity: 'base' })
            const currencies = state.currencies.sort((a, b) => {
                return collator.compare(a.text, b.text)
            })
            const firsts = [null, null, null]
            for (const symbol of currencies) {
                switch (symbol.value) {
                    case 'CZK':
                        firsts[0] = symbol
                        break
                    case 'EUR':
                        firsts[1] = symbol
                        break
                    case 'USD':
                        firsts[2] = symbol
                        break
                }
                if (firsts.filter(item => item).length === 3) {
                    break
                }
            }
            return [...firsts, ...currencies.filter(item => !['CZK', 'EUR', 'USD'].includes(item.value))]
        },
        riskActivities (state) {
            const collator = new Intl.Collator(state.lang, { numeric: true, sensitivity: 'base' })
            const riskActivities = state.riskActivities.map((riskActivity) => {
                riskActivity.text = i18n.t(`riskActivities.riskActivity${riskActivity.value}`)
                return riskActivity
            }).sort((a, b) => {
                return collator.compare(a.text, b.text)
            })
            const first = riskActivities.find(item => item.value === 140)
            return [first, ...riskActivities.filter(item => item.value !== 140)]
        },
        genders (state) {
            return state.genders.map(gender => ({ ...gender, text: i18n.t(`personalId.${gender.value}`) }))
        },
        realOwnerTypes (state) {
            return state.realOwnerTypes
        },
        statutoryTypes (state) {
            return state.statutoryTypes.map(type => ({ ...type, text: i18n.t(`statutoryTypes.${type.value}`) }))
        },
        realOwnerPurposeTypes (state) {
            return state.realOwnerPurposeTypes
        },
        personalIdTypes (state) {
            return state.personalIdTypes.map(type => ({ ...type, text: i18n.t(`personalIdTypes.${type.value}`) }))
        },
        otherItems (state) {
            return state.otherItems.map(item => ({
                ...item,
                description: i18n.t(`otherItems.${item.list}.description`),
                items: item.items.map(subitem => ({
                    ...subitem,
                    description: i18n.t(`otherItems.${item.list}.items.${subitem.code}`)
                })).sort((a, b) => a.order - b.order)
            }))
        }
    },
    mutations: {
        setEnum (state, data) {
            const enums = [
                'countries',
                'personalIdTypes',
                'genders',
                'currencies',
                'moneyOrigins',
                'riskActivities',
                'realOwnerTypes',
                'statutoryTypes',
                'realOwnerPurposeTypes',
                'otherItems'
            ]
            if (data.enum && enums.includes(data.enum) && Array.isArray(data.content)) {
                state[data.enum] = data.content
            } else {
                throw new Error('Invalid setEnum mutation data structure')
            }
        }
    },
    actions: {

    }
}
